<template>
  <div class="progress-loader">
    <div
      v-for="(line, index) in progressLines"
      :key="index"
      class="progress-line"
      :class="{
        'is-filled': loadProgress >= line,
      }"
    ></div>
  </div>
</template>
<script>
export default {
  data: () => ({
    interval: 0,
    loadProgress: 0,
  }),
  props: {
    isLoaded: {
      type: Boolean,
      default: false,
    },
    linesCount: {
      type: Number,
      default: 5,
    },
    infinite: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    progressLines() {
      return [...Array(this.linesCount).keys()];
    },
  },
  created() {
    if (this.infinite) this.runInfiniteProgress();
    else this.runDefaultProgress();
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    runInfiniteProgress() {
      this.interval = setInterval(() => {
        if (this.loadProgress !== this.linesCount - 1) {
          this.loadProgress += 1;
        } else {
          this.loadProgress = 0;
        }
      }, 500);
    },
    runDefaultProgress() {
      this.interval = setInterval(() => {
        if (this.loadProgress >= this.linesCount) clearInterval(this.interval);
        if (this.isLoaded) {
          this.loadProgress = this.linesCount;
          clearInterval(this.interval);
        }
        if (!this.isLoaded && this.loadProgress < this.linesCount - 2)
          this.loadProgress += 1;
      }, 500);
    },
  },
};
</script>
<style lang="scss" scoped>
.progress-loader {
  height: 18px;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  .progress-line {
    background: #d9d9d9;
    transform: skew(-45deg);
    transition: all 0.2s ease-in-out;
    height: 100%;
    width: 8px;
    margin: 0 4px;
  }
  .is-filled {
    background: #cc092f;
  }
}
</style>